<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-2">Break Types</h6> 
        <!-- Only show Remove button when not in edit mode -->
        <button v-if="!editMode" @click="toggleEditMode" class="btn btn-dark btn-sm">
           Remove 
        </button>
      </div> 
    </div>
    <div class="p-3 card-body"> 
      <div class="scroll-type-md">
        <span v-for="(value, index) in breakItems" :key="index" class="text-sm types">
          {{ value }}
          <!-- Show the remove 'x' icon only in edit mode -->
          <span v-if="editMode" class="cross-icon" @click="removeType(index, value)">✖</span>
        </span>
      </div>

      <!-- Show Add input and button only when not in edit mode -->
      <div class="mt-2" v-if="!editMode">
        <input 
          type="text"
          v-model="newType"
          @keyup.enter="addType"
          maxlength="200"
          placeholder="Add Break Type"
          class="form-control handle-focus form-control-md fs-6"
        />
        <button @click="addType" class="btn bg-gradient-success btn-sm mt-2">Add</button>
      </div>

      <!-- Show Cancel button in edit mode -->
      <div class="mt-2" v-if="editMode">
        <a @click="cancelEditMode" class="float-end text-success text-gradient mt-2 cancel-link">
          Back to Add
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { isAuth } from '../composables/userAuth';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'; 
import apiClient from '../../../api/apiClient';
import { useMeta } from '../composables/useMetaTags';

useMeta(
  'Break Types Management',
  'Manage break types, add, remove, or edit them as needed.'
);

const userId = Cookies.get('id'); 
let breakItems = ref([]);

const props = defineProps({
   breakTypes: { type: Array, required: true, default: () => []  }
});
const breakTypes = ref(props.breakTypes); 

watch(() => props.breakTypes, (newBreakTypes) => {  
  if (newBreakTypes) {
    breakItems.value = [...newBreakTypes];
  }
}, { immediate: true });

const emit = defineEmits(["update:emitUpdateBreakTypes"]);
const editMode = ref(false);
const newType = ref('');
 
const toggleEditMode = () => {
  if(isAuth()) {
    editMode.value = true;
  }
};
 
const cancelEditMode = () => {
  editMode.value = false;
};

const updateBreakTypes = async () => { 
  try {
    await apiClient.put(`/type/${userId}`, { type: breakItems.value });
    breakTypes.value = breakItems.value;
    emit('update:emitUpdateBreakTypes', breakTypes.value);
  } catch (error) {
    console.error('Error break/user:', error);
  }
};
 
const removeType = async (index, val) => {
  Swal.fire({
    title: 'Are you sure?',
    html: "Do you really want to delete this break type?<br> <span class='text-danger font-weight-bolder'>NOTE:</span> This action will also <strong>DELETE</strong> all breaks that use this break type.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        breakItems.value.splice(index, 1);
        const response = await apiClient.put(`/type/${userId}`, {
          type: breakItems.value,
          deletedType: val 
        });
        if (response.data) {
          Swal.fire('Deleted!', 'The break type has been deleted.', 'success');
          location.reload(true);
        }
      } catch (error) {
        console.error('Error break/user:', error);
        Swal.fire('Error!', 'There was an error deleting the break type.', 'error');
      }
    }
  });
};

// Add a new break type
const addType = async () => {
try{
  if(isAuth()) {
    if (newType.value?.trim()) {
      breakItems.value.push(newType.value.trim());
      await updateBreakTypes();     
      newType.value = '';
    }
  }
  }catch(error){
      console.error('Error break/get:', error);
      }
};
</script>


<style scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
}

.card-body {
  padding: 15px;
}

.types {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 2px 8px;
  margin: 4px;
  display: inline-block;
  position: relative;
}

.cross-icon {
  color: red;
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}

.mt-2 {
  margin-top: 10px;
}

.form-control-sm {
  width: 200px;
  display: inline-block;
}

.btn-sm {
  margin-top: 5px;
}

.scroll-type-md {
  max-height: 240px;
  overflow-y: auto;
}
</style>
