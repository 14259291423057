<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <!-- Copyright Section -->
        <div class="col-12 col-md-6 mb-4 mb-md-0">
          <div class="text-sm copyright text-lg-start text-muted text-center text-md-left">
            © {{ new Date().getFullYear() }} <span class="text-success">Work Break</span>
          </div>
        </div>

        <!-- Footer Links Section -->
        <div class="col-12 col-md-6">
          <ul class="nav nav-footer justify-content-center justify-content-md-end">
            <li class="nav-item">
              <a @click="openContactUs" class="nav-link cursor-pointer link-primary">Contact Us</a>
            </li>
            <li class="nav-item">
              <a @click="openContactUs" class="nav-link cursor-pointer link-primary">About Us</a>
            </li>
            <li class="nav-item">
              <a href="/privacy-policy" class="nav-link cursor-pointer link-primary">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <contactUs v-if="isContactUs" @update:emitCloseContactUs="closeContactUs"></contactUs>
  </footer>
</template>
<script setup>  
import { ref, nextTick } from 'vue';
import contactUs from '../views/components/common/conatct-us.vue';
import { useMeta } from '../../src/views/components/composables/useMetaTags';
useMeta(
  'Footer - Work Break',
  'Explore Work Break’s footer links for easy navigation to contact us, learn more about us, or review our privacy policy.'
);

let isContactUs = ref(false);

const openContactUs = () => {
  isContactUs.value = true;
}
 
const closeContactUs = async () => {
  await nextTick();
  isContactUs.value = false;
}

</script>
<style scoped>
  ul {
    margin: 0 -10px;
  }
</style>
