<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <Breaks />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <Tasks />
      </div>
    </div>
  </div>
</template>

<script setup>
import Breaks from "./components/archives/Breaks.vue";
import Tasks from "./components/archives/Tasks.vue";
import { useStore } from "vuex";
import { useMeta } from './components/composables/useMetaTags';
useMeta('Dashboard | WorkBreak - Manage Your Breaks and Tasks Efficiently', 'Welcome to your personalized dashboard on WorkBreak. Track and manage your breaks, tasks, and priorities efficiently. Get insights into your current and past performance with interactive charts, detailed lists, and real-time updates. Start managing your work hours today and boost productivity.');

const store = useStore();
store.state.isAbsolute = false;
store.commit("isDashboard", false);
</script>
