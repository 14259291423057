<template>
  <router-link :to="to" class="nav-link" @click="minimizeSidebar">
    <div
      class="icon icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-2">{{
      navText
    }}</span>
  </router-link>
</template>
<script setup> 
import { useStore } from "vuex";
import { useMeta } from '../../../src/views/components/composables/useMetaTags';

useMeta(
  `Navigation Link - Work Break`,
  `Explore the various navigation options within the Work Break platform, including links to key sections like Dashboard, Tasks, and Profile.`
);

const store = useStore(); 
const sidebarMinimize = () => store.commit("sidebarMinimize");

const minimizeSidebar = () => {
  if (window.innerWidth < 800) {
    sidebarMinimize();
  }
};

defineProps({
  to: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
});
</script>