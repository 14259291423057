<template>
  <div class="d-flex align-items-center justify-content-center">
    <p class="no-record-message text-danger">No records have been added yet. Please add a new record to get started.</p>
  </div>
</template>

<script setup>
 import { useMeta } from '../composables/useMetaTags';

useMeta(
  "No Records Found - WorkBreak",
  "No records available yet. Add a new record to start managing your data efficiently with WorkBreak."
);
</script>

<style scoped>
@media (min-width: 992px) {
  .no-record-message {
    font-size: 1.5rem; /* Slightly larger text */
    font-weight: 500; /* Medium weight text */
  }
}
</style>
