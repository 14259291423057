<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold fw-bold">Track Your Breaks and Stay Balanced!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md"><span class="text-danger">You haven’t logged any breaks yet</span>—regular breaks boost productivity and well-being. Start tracking your breaks now with time, date, and reason, and monitor patterns using our simple charts for a balanced workday.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Track Your Breaks - WorkBreak",
  "You haven’t logged any breaks yet. Start tracking your breaks now and maintain a balanced workday with detailed insights into your break times and reasons."
);

</script>
 
