<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg">Track Your Leaves with Ease</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
     <p class="text-md">Want to keep track of your leaves and ensure you stay organized? Our platform makes it easy to log both single and multiple leave days, complete with reasons for each one. By signing in, you’ll unlock the ability to update or edit your leave records at any time. <a @click="openSignIn" class="cursor-pointer text-success start-link">Sign in</a> or create an account now to start managing your leaves and stay in control of your time off!</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Track Your Leaves - WorkBreak",
  "Easily track and manage your leave records. Sign in to update and maintain a clear overview of your time off with WorkBreak."
);

const openSignIn = () => {
try{
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
      }catch(error){
      console.error('Error break/get:', error);
      }
  }
</script>
 
