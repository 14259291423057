<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold">Take Control of Your Time – Log Your Breaks!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Log your breaks to maintain a healthy work-life balance and manage time effectively with detailed charts and insights. <a @click="openSignIn" class="cursor-pointer text-success start-link">Sign in</a> or create an account to start tracking and boost productivity with our intuitive break management system.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Break Management - WorkBreak",
  "Log your breaks effectively to manage work-life balance and boost productivity. Sign in or create an account to start tracking your breaks and view detailed insights."
);

const openSignIn = () => {
try{
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
    }catch(error){
      console.error('Error break/get:', error);
      }
  }
</script>
 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>