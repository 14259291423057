
<template>
 <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header color-black">
                        <h5 class="modal-title">
                            Terms and Conditions
                        </h5>
                        <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close" ><i class="custom-icon close-icon icon-lg"></i></button>
                    </div>
        <div class="modal-body">  
             <div class="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">
  <div class="markdown prose w-full break-words dark:prose-invert light">
    <p>
      <strong>Effective Date:</strong> 08/20/2024
    </p>
    <hr>
    <h4>
      <strong>1. Introduction</strong>
    </h4>
    <p>Welcome to WORK BREAK. These Terms and Conditions govern your use of our website located at our website and any related services provided by WORK BREAK. By accessing or using the website, you agree to be bound by these terms. If you disagree with any part of the terms, you must not use our website.</p>
    <hr>
    <h4>
      <strong>2. Definitions</strong>
    </h4>
    <ul>
      <li>
        <strong>Website</strong> refers to WORK BREAK and all its related services.
      </li>
      <li>
        <strong>User</strong> refers to any individual or entity that accesses or uses the website.
      </li>
    </ul>
    <hr>
    <h4>
      <strong>3. User Obligations</strong>
    </h4>
    <p>Users agree to use the website only for lawful purposes and in a manner that does not infringe the rights of others. Users must not post or transmit any content that is illegal, offensive, or violates the rights of others.</p>
    <hr>
    <h4>
      <strong>4. Intellectual Property</strong>
    </h4>
    <p>All content on this website, including text, graphics, logos, and images, is the property of WORK BREAK or its content suppliers and is protected by copyright and other intellectual property laws. Users may not reproduce, distribute, or create derivative works from this content without prior written permission.</p>
    <hr>
    <h4>
      <strong>5. User-Generated Content</strong>
    </h4>
    <p>Users may submit content to the website, such as comments and reviews. By submitting content, users grant WORK BREAK a non-exclusive, worldwide, royalty-free license to use, modify, and display the content. WORK BREAK reserves the right to remove any user-generated content that violates these terms or is deemed inappropriate.</p>
    <hr>
    <h4>
      <strong>6. Privacy Policy</strong>
    </h4>
    <p>Your privacy is important to us. We will always protect your information. Your personal information will never ever be shared with any one. </p>
    <hr>
    <h4>
      <strong>7. Limitation of Liability</strong>
    </h4>
    <p>WORK BREAK is not liable for any damages that may arise from the use of the website, including but not limited to direct, indirect, incidental, or consequential damages. We do not guarantee that the website will be available at all times or that the information provided is accurate and free from errors.</p>
    <hr>
    <h4>
      <strong>8. Termination</strong>
    </h4>
    <p>We reserve the right to terminate or suspend any user's access to the website at our discretion, without notice, for conduct that we believe violates these terms or is harmful to other users.</p>
    <hr>
    <h4>
      <strong>9. Governing Law</strong>
    </h4>
    <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and any disputes will be resolved in the courts of [Your Country/State].</p>
    <hr>
    <h4>
      <strong>10. Changes to Terms</strong>
    </h4>
    <p>We reserve the right to update these terms at any time. We will notify users of any significant changes by posting a notice on the website. Continued use of the website after any changes indicates acceptance of the new terms.</p>
    <hr>
    <h4>
      <strong>11. Contact Information</strong>
    </h4>
    <p>If you have any questions about these terms, please contact us at [Your Contact Email or Address].</p>
    <hr>
    <p>You can now use this text as the content of a modal that users must agree to before they create an account on your website.</p>
     
</div></div>
            </div> 
          </div> 
      </div> 
  </div>   
</template>
<script setup>
import { onMounted,shallowRef} from 'vue';
import { Modal } from "bootstrap"; 
import { useMeta } from '../components/composables/useMetaTags';

useMeta('Terms and Conditions - WorkBreak', 'Read the Terms and Conditions for using WORK BREAK. Understand the rules, user obligations, privacy policy, and intellectual property rights before accessing our website.');
const modalRef = shallowRef(null);
 let modal = null;
 

// Check cookies on mounted
  onMounted(() => {
    console.log("Checking cookies on mounted");
      modal = new Modal(modalRef.value, {
        backdrop: 'static',
      });
         modal.show('static'); 
  });  
  const closeDialog = () => { 
        modal.hide();
  };
        
  
</script> 
<style scoped>
  .modal-body {
    max-height: 80vh; /* Set your desired max height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
</style>