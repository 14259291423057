 <template>
  <div class="card z-index-2 p-3">
     <div class="pb-0 border-radius-xs border-radius-md border-radius-lg">
          <div class="d-flex justify-content-between">
            <h6 class="pb-1">All TASKS</h6>
            <span class="">Total Tasks : {{ recordCount }}</span>
          </div>
        </div>
         <taskInfo2 v-if="token && tasks?.length===0"></taskInfo2>
        <taskInfo3 v-if="!token"></taskInfo3>
        <div v-if="tasks?.length===0" class="row">
          <a @click="addTaskManually" class="col-12 text-end text-success p-4 start-link">Add Task</a>
        </div>
    <div v-if="tasks?.length>0" class="row">
              <div class="mb-3 col-md-3 col-sm-12">
                  <label for="title" class="form-label">Title</label>
                  <input type="text" class="form-control" v-model="title">
              </div>
              <div class="mb-3 col-md-3 col-sm-12">
                  <label for="currentDate" class="form-label">Scheduled On</label>
                  <input type="date" class="form-control" v-model="scheduled">
              </div>
              <div class="mb-2 col-md-2 col-sm-12">
                  <label for="type" class="form-label">Task Status</label>
                  <select class="form-select form-control-alternative" v-model="status">
                  <option value="To Do">To Do</option>
                  <option value="In Progress">In Progress</option>
                  <option value="In Review">In Review</option>
                  <option value="Completed">Completed</option>
                  <option value="Invalid">Invalid</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Deferred">Deferred</option>
                  <option value="Blocked">Blocked</option>
                  </select> 
              </div>
               <div class="mb-2 col-md-2 col-sm-12">
                  <label for="type" class="form-label">Priority Levels</label>
                  <select class="form-select handle-focus" v-model="priority">
                  <option value="Normal">Normal</option>
                  <option value="Important">Important</option>
                  <option value="Critical">Critical</option>
                  <option value="Low">Low</option>
                  <option value="High">High</option>
                  <option value="Urgent">Urgent</option>
                  <option value="Blocker">Blocker</option>
                  </select> 
              </div>
               <div class="mb-2 col-md-2 col-sm-12 mt-2 pt-4">
                 <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="getTasks(1)">
                Search
            </button>    
        </div>
            </div> 
 
        <loading v-if="isLoading"></loading>
        <div class="card" v-if="!isLoading">
 
      <div v-if="tasks?.length>0" class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Description
               </th>
               <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Scheduled
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Priority
              </th>
               <th
                class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
           
            <tr v-for="(value, index) in tasks" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto"> 
                    <h6 class="mb-0 text-sm handle-large-text" :title="value.title">{{value.title}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.description">{{ value.description }}</p>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.scheduled">{{ convertDate(value.scheduled) }}</p>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.status }}</span>
              </td> 
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.priority }}</span>
              </td> 
              <td class="text-end">
                <span class="me-2 mouse-pointer" @click="viewTaskDetails(value)"><i class="custom-icon view-icon"></i></span>
                <span class="me-3 mouse-pointer" @click="deleteTask(value._id)"><i class="custom-icon trash-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="editTask(value)"><i class="custom-icon edit-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
 </div>
 <div class="row">
      <div class="d-inline-block col-10">
        <Pagination v-if="recordCount>10" :records-per-page="recordsPerPage" :record-count="recordCount" @changePage="onChangePage"
          :current-page="pageNumber"></Pagination>
      </div>
      </div>
  </div>
  <ViewTask v-if="showViewModal" :task-details="selectedTask" @close="showViewModal = false"></ViewTask>
  <AddTask v-if="showEdit" :edit-record="selectedTask" is-archives="true"  @update:archivesEditTask="getTasks(1)"></AddTask>
 </template>

<script setup>
import {ref, onMounted, computed, nextTick} from 'vue';
import AddTask from '../../components/tasks/AddTask';
import Pagination from './pagination';
import apiClient from '../../../api/apiClient';
import { convertDate } from "../../utilities/transforms";
import { deleteRecord } from '../composables/deleteRecords';
import { isAuth } from '../composables/userAuth';
import ViewTask from '../../components/tasks/ViewTask';
import loading from '../common/loading.vue';
import taskInfo2 from "../common/task-info2.vue";
import taskInfo3 from "../common/task-info3.vue";
import { useMeta } from '../composables/useMetaTags';

useMeta(
  'Task List',
  'View and manage your tasks. You can filter tasks by title, scheduled date, status, and priority, or add new tasks to the list.'
);

import Cookies from 'js-cookie';
let showEdit = ref(false);
let showViewModal = ref(false);
const selectedTask = ref(null);
const userId = Cookies.get('id');
const recordsPerPage = ref(10);
  let pageNumber = ref(1);
  let tasks = ref([]);
  let recordCount = ref(0);
  let scheduled = ref(null);
  let status = ref('');
  let priority = ref('');
  let title = ref('');
  let isLoading = ref(false); 
  const emit = defineEmits(['taskCounts']);

 const token = computed(() => {
      return Cookies.get('access_token');;
  });
      const getTasks = async (page) => { 
          try { 
              isLoading.value = true;
              pageNumber.value = page;
              tasks.value = [];
              const limit = recordsPerPage.value;
              const skip = (pageNumber.value - 1) * recordsPerPage.value;
              let params = {
                userId,
                limit,
                skip
              }  
              if (status.value) {
                params.status = status.value;
              }
              if (priority.value) {
                params.priority = priority.value;
              }
              if (title.value) {
                params.title = title.value;
              }
              if (scheduled.value) {
                params["scheduled"] = scheduled.value;
              }
              const response = await apiClient.get('/task/getArchivesTasks', {
              params 
            });
            if(response?.data){
            tasks.value = response.data.tasks;
            recordCount.value = response.data.totalCount; 
            if(recordCount.value === 0){
               emit('taskCounts');
            }
            console.error('tasks.value:', tasks.value);
            }
            } catch (error) {
              console.error('Error responseForToday:', error);
            }
              finally { 
              isLoading.value = false;
            }
          } 

    const viewTaskDetails = async (task) => {
    try{
      showViewModal.value = false;
      await nextTick(); 
      selectedTask.value = task;
      showViewModal.value = true;
      }catch(error){
      console.error('Error break/get:', error);
      }
    };
   
    const deleteTask = async (id) => {
    try{
      const repsonseId = await deleteRecord(id, "task");
    console.log("delete response", repsonseId);
    if(repsonseId) { 
      await getTasks(1);
    }
    }catch(error){
      console.error('Error break/get:', error);
      }
  }

  const editTask = async (task) => {
  try{ 
     showEdit.value = false;
    await nextTick(); 
    selectedTask.value = {...task};
    showEdit.value = true;
    }catch(error){
      console.error('Error break/get:', error);
      }
  };

  const addTaskManually = async() => {
  try{
      if(isAuth()) { 
        showEdit.value = false;
        await nextTick(); 
        selectedTask.value = null;
        showEdit.value = true;
      }
      }catch(error){
      console.error('Error break/get:', error);
      }
    }

    const onChangePage = async (newPage) => {
    try{
      tasks.value = [];
      isLoading.value = true;
      window.scrollTo(0, 0);
      await getTasks(newPage);
      }catch(error){
      console.error('Error break/get:', error);
      }
    };
 
    
    onMounted( async() => { 
      await getTasks(1);
    });

</script> 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>