// src/composables/useMetaTags.js
import { useHead } from '@vueuse/head';

/**
 * useMeta composable for updating title and description dynamically
 * @param {string} title - The page title
 * @param {string} description - The meta description for the page
 */
export function useMeta(title, description) {
  useHead({
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
    ],
  });
}
