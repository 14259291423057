<template>
   <div ref="modalRef" class="modal fade" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header color-black">
                        <h5 class="modal-title">
                            Contact Us
                        </h5>
                        <button type="button" class="border-0 bg-transparent" @click="closeDialog()" aria-label="Close" ><i class="custom-icon close-icon icon-lg"></i></button>
                    </div>
        <div class="modal-body">  
    <form @submit.prevent="submitForm">
      <!-- Email Field -->
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input
          type="email"
          id="email"
          v-model="form.email"
          class="form-control"
          :class="{ 'is-invalid': validationErrors.email }"
          placeholder="Enter your email"
          required
        />
        <div v-if="validationErrors.email" class="invalid-feedback">
          Please provide a valid email.
        </div>
      </div>

      <!-- Subject Field -->
      <div class="mb-3">
        <label for="subject" class="form-label">Subject (Max:200)</label>
        <input
          type="text"
          id="subject"
          v-model="form.subject"
          maxlength="200"
          class="form-control"
          :class="{ 'is-invalid': validationErrors.subject }"
          placeholder="Enter subject"
          required
        />
        <div v-if="validationErrors.subject" class="invalid-feedback">
          Subject is required.
        </div>
      </div>

      <!-- Description Field -->
      <div class="mb-3">
        <label for="description" class="form-label">Description (Max:3000)</label>
        <textarea
          id="description"
          v-model="form.description"
          class="form-control"
          maxlength="3000"
          :class="{ 'is-invalid': validationErrors.description }"
          rows="4"
          placeholder="Enter your message"
          required
        ></textarea>
        <div v-if="validationErrors.description" class="invalid-feedback">
          Description is required.
        </div>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary float-end">Submit</button>
    </form> 
  </div>
  </div>
  </div>
  </div>
</template>

<script setup>
import { ref, shallowRef, onMounted } from 'vue';
import { Modal } from "bootstrap"; 
import axios from 'axios';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2';
const userId = Cookies.get('id');
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Contact Us - WorkBreak",
  "Get in touch with us for any queries or support. Fill out the contact form to send us an email regarding your concerns or requests."
);
const modalRef = shallowRef(null);
let modal = null;
// Form state
const form = ref({
  email: '',
  subject: '',
  description: '',
  userId: ''
});

// Validation errors state
const validationErrors = ref({
  email: false,
  subject: false,
  description: false,
});

// Form submit handler
const submitForm = async () => {
  resetValidation(); 
  validationErrors.value.email = !isValidEmail(form.value.email);
  validationErrors.value.subject = form.value.subject.trim() === '';
  validationErrors.value.description = form.value.description.trim() === '';

  if (!validationErrors.value.email && !validationErrors.value.subject && !validationErrors.value.description) {
    console.log('Form submitted:', form.value);
     try {
      if(userId && userId !==''){
        form.value.userId = userId;
      }
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/contactUs/send`,form.value);
        if (response?.data === 'message sent!') {
          Swal.fire({
              icon: 'info',
              title: 'Notice',
              text: 'Message sent successfully.',
              confirmButtonText: 'OK'
          });
        }
       } catch (error) {
        console.error('Error:', error);
        Swal.fire({
              icon: 'error',
              title: 'Notice',
              text: 'Error in sending message.',
              confirmButtonText: 'OK'
          });
      }
      finally{ 
        closeDialog();
      }
  }
};

// Helper function to reset validation errors
const resetValidation = () => {
try{
  validationErrors.value = {
    email: false,
    subject: false,
    description: false,
  };
  }catch(error){
      console.error('Error break/get:', error);
      }
};

// Helper function to validate email
const isValidEmail = (email) => {
try{
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
  }catch(error){
      console.error('Error break/get:', error);
      }
};
 const emit = defineEmits(["update:emitCloseContactUs"]);
const closeDialog = () => {  
try{
    modal.hide();
    emit('update:emitCloseContactUs');
    }catch(error){
      console.error('Error break/get:', error);
      }
 };
 
onMounted(() => {
try{
  modal = new Modal(modalRef.value, {
    backdrop: 'static',
  });
  modal.show('static'); 
  }catch(error){
      console.error('Error break/get:', error);
      }
});
</script>

