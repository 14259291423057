<template>
  <div class="mb-3 card">
    <div class="p-3 card-body card-height" :class="isEmptyObject(tasks)? 'warning-color':''">
      <div class="row">
        <!-- Priority Section -->
        <div class="col-12" v-if="tasks && tasks.byPriority && Object.keys(tasks.byPriority).length > 0">
          <span class="fw-bold">🚩: </span>
          <template v-for="(priority, index) in Object.entries(tasks.byPriority)" :key="priority[0]">
            <span :class="getPriorityClass(priority[0])">
              {{ priority[1] }} {{ priority[0] }}
            </span>
            <span v-if="index < Object.entries(tasks.byPriority).length - 2">, </span>
            <span v-else-if="index === Object.entries(tasks.byPriority).length - 2"> and </span>
          </template>
        </div>

        <!-- Status Section -->
        <div class="col-12" v-if="tasks && tasks.byStatus && Object.keys(tasks.byStatus).length > 0">
          <span class="fw-bold">✔️: </span>
          <template v-for="(status, index) in Object.entries(tasks.byStatus)" :key="status[0]">
            <span :class="getStatusClass(status[0])">
              {{ status[1] }} {{ status[0]}}
            </span>
            <span v-if="index < Object.entries(tasks.byStatus).length - 2">, </span>
            <span v-else-if="index === Object.entries(tasks.byStatus).length - 2"> and </span>
          </template>
        </div>
      </div> 
       <div v-if="isEmptyObject(tasks)" class="col-12">
         <span class="text-muted" role="alert">No <strong>Urgent</strong> or <strong>In-progress</strong> tasks.</span> 
          <a href="/tasks" class="cursor-pointer link-primary font-weight-bolder d-block">View the task list to continue</a>
        </div>
    </div>
  </div>
</template> 

<script setup>
import { onMounted, ref } from 'vue'
import apiClient from '../../api/apiClient';
import { useMeta } from '../../../src/views/components/composables/useMetaTags';

useMeta(
  'Task Overview by Priority and Status',
  'View a summary of your tasks organized by priority and status. Quickly identify urgent and in-progress tasks and continue managing them through the task list.'
);

import Cookies from 'js-cookie'; 
const userId = Cookies.get('id');
let tasks = ref({}); 

const taskCounts = async () => {
try {  
        const response = await apiClient.get('/settings/priority-status', {
            params: { userId }
        }); 
        console.log('taskCounts:', response.data); 
        tasks.value = response.data;   
      } catch (error) {
        console.error('getInfo:', error);
      } 
}

const isEmptyObject = (obj) => {
    if (!obj || !obj.byPriority || !obj.byStatus) {
    return true; // Consider it empty if undefined or null
  }
  return Object.keys(obj.byPriority).length === 0 && Object.keys(obj.byStatus).length === 0;
} 

const getPriorityClass = (priority) => {
  const priorityColors = {
    Urgent: "text-danger", // Red
    Critical: "text-warning", // Yellow
    Important: "text-primary", // Blue
  };
  return priorityColors[priority] || "text-muted";
};

const getStatusClass = (status)=> {
  const statusColors = {
    "To Do": "text-secondary", // Grey
    "In Progress": "text-info", // Light Blue
    "In Review": "text-success", // Green
  };
  return statusColors[status] || "text-muted";
}

onMounted( async()=>{
  await taskCounts();
  // if(!props.count){
  //   counts.value = 0;
  // } else {
  //   counts.value = props.count;
  // }
})
</script>

<style scoped>
  .bg-gray {
    background: #e9ecef;
  }
  .current-break {
    margin-top: -4px;
  }
  .card-height {
    height: 82.39px;
  }
  .warning-color {
    background-color: #ffc107;
    border-radius: 14px;
  }
</style>

