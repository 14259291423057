<template>
 <div v-if="token" class="card z-index-2 p-3"> 
  <div class="row mb-2">
  <span class='col-10 pt-2'>Working hours(default is 9)</span>
   <select class='col-2 form-select form-control-alternative w-15' v-model="selectedNumber" @change="updateHours">
      <option v-for="number in numbers" :key="number" :value="number">{{ number }}</option>
    </select> 
  </div>     
  <canvas id="pieChart" width="300" height="300"></canvas>
            </div> 
  <div v-else class="card z-index-2 p-3">
            <carousel
      :images="[
        { src: '/carousel/bpi1.png', alt: 'pi chart1' },
        { src: '/carousel/bpi2.png', alt: 'pi chart2' },
        { src: '/carousel/bpi3.png', alt: 'pi chart3' }
      ]"
      id="carousel-bpi"
    /></div>
</template>

<script setup> 
import { onMounted, shallowRef, ref, computed, watch } from "vue";
import Chart from "chart.js/auto";
import Cookies from 'js-cookie'; 
import carousel from '../common/carousel.vue';
import { getDurations, convertMinutesToHoursAndMinutes, trimLabels } from '../../utilities/transforms';
import { useMeta } from '../composables/useMetaTags';
useMeta(
      'Working Hours: 9 hours',
      'The chart initially shows 9 hours of working time and 15 hours of free time.'
    );
    
let dutyMinutes = shallowRef(540);
let chartKeys = shallowRef(null);
let chartValues = shallowRef(null);
let breakMinutes =  shallowRef(0);
let breakTime =  shallowRef(null);
let pieChart = shallowRef(null); 
let todayBreakList = shallowRef([]);  
const token = computed(() => {
      return Cookies.get('access_token');;
  });
const selectedNumber = ref(9); 
const numbers = Array.from({ length: 24 }, (_, i) => i + 1)
const props = defineProps({
  breaksList  : { type : Object, required:true },
})
 
    const updateHours = async() => {
    try{
      if(pieChart.value) {
        pieChart.value.destroy();
      }
      dutyMinutes.value = selectedNumber.value * 60;
      chartValues.value[0] = dutyMinutes.value - breakMinutes.value;
      await drawGraphs();
      }catch(error){
      console.error('Error break/get:', error);
      }
    }

    watch(() => props.breaksList, async() => {
       if(!props.breaksList) {
        return;
      }
      todayBreakList.value = props.breaksList.todayData;
       if(pieChart.value) {
        pieChart.value.destroy();
      }
      await calculatKeyValueFromData();
      dutyMinutes.value = selectedNumber.value * 60;      
      await drawGraphs();
    });

    const drawGraphs = async() => {
    try{
    const ctx = document.getElementById('pieChart').getContext('2d');
      const trimmedBreakTypeList = trimLabels(chartKeys.value, 20);
      pieChart.value = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: trimmedBreakTypeList,
          datasets: [{
            label: 'Minute(s)',
            data: chartValues.value,
            borderWidth: 2
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: `${selectedNumber.value} Duty hours AND so far ${breakTime.value} is Break Durations`
            }
          }
        },
      });
      }catch(error){
      console.error('Error break/get:', error);
      }
    }

    const calculatKeyValueFromData = async() => {
    try{
      console.log("inside pichart onMounted", todayBreakList.value)
        const durations = await getDurations(todayBreakList.value); 
        const keys = Object.keys(durations);
        const values = Object.values(durations);
        breakMinutes.value = values.reduce((acc, curr) => acc + curr, 0);
        breakTime.value = convertMinutesToHoursAndMinutes(breakMinutes.value);
        // these are the actual minutes spend in office for work
        // remove the breaks minutes from working minutes
        const workingMinutes = dutyMinutes.value - breakMinutes.value;
        chartKeys.value =keys? ['Working Duration', ...keys]:durations;
        chartValues.value = values? [workingMinutes, ...values]:durations; 
        }catch(error){
      console.error('Error break/get:', error);
      }
    }

    onMounted( async()=>{
    try{
      if(!props.breaksList) {
        return;
      }
        console.log("inside pichart onMounted", props.breaksList.todayData) 
        todayBreakList.value = props.breaksList.todayData;
        if(todayBreakList.value) {
          await calculatKeyValueFromData();
          await drawGraphs()
        }
      }catch(error){
      console.error('Error break/get:', error);
      }
   })
</script>

<style scoped>
 
</style>
