 <template>
<div class="m-2 p-2">
    
    <p><strong>Effective Date: October 29, 2024</strong></p>
    <p class="card p-3">At Work-Break, accessible from <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://work-break.com">https://work-break.com</a>,
        we are committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of information that we collect from users of our website and services.</p>
    <h3>Information We Collect</h3>
    <p>We collect the following personal information when you register and use WorkBreak:</p>
    <ul>
        <li><strong>Name</strong></li>
        <li><strong>Email Address</strong></li>
        <li><strong>Password</strong></li>
        <li><strong>Break, Task, and Leave Data</strong></li>
    </ul>
    <h3>How We Use Your Information</h3>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
        <li><strong>Account Management</strong>: To create and manage your user account and provide our services.</li>
        <li><strong>Service Updates</strong>: To inform you about updates or changes to our services.</li>
        <li><strong>Interactive Features</strong>: To allow you to use features such as managing breaks, tasks, and leave requests.</li>
        <li><strong>Customer Support</strong>: To assist you with any questions or issues you may have.</li>
        <li><strong>Usage Analysis</strong>: To analyze user trends and improve the quality of our services.</li>
    </ul>
    <h3>Data Retention</h3>
    <p>We retain your personal information only for as long as necessary for the purposes set out in this Privacy Policy. When your data is no longer needed, it will be deleted in accordance with our data deletion policy.</p>
    <h3>User Rights</h3>
    <p>You have the following rights regarding your personal information:</p>
    <ul>
        <li><strong>Access</strong>: Request access to your information.</li>
        <li><strong>Correction</strong>: Request correction of any inaccuracies.</li>
        <li><strong>Deletion</strong>: Request deletion of your personal information.</li>
    </ul>
    <h3>User Data Deletion</h3>
    <p>To request the deletion of your personal data, please visit the following URL: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://work-break.com/delete-account">https://work-break.com/delete-account</a></p>
    <h3>Security</h3>
    <p>We are committed to ensuring the security of your information. Reasonable security measures are implemented to protect against unauthorized access, alteration, or disclosure.</p>
    <h3>Changes to This Privacy Policy</h3>
    <p>We may update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
    <h3>Contact Us</h3>
    <p>If you have any questions about this Privacy Policy, please contact us:</p>
    <ul>
        <li><strong>Email</strong>: info@work-break.com</li>
        <li><strong>Website</strong>: <a class="cursor-pointer link-primary" rel="noopener" target="_new" href="https://work-break.com">https://work-break.com</a></li>
    </ul>
</div>
</template>
<script setup>
import { useStore } from "vuex";
import { useMeta } from '../composables/useMetaTags';
useMeta(
  'Privacy Policy - WorkBreak',
  'Understand our privacy practices and how Work Break protects your information. Learn about data collection, usage, and your rights as a user.'
);
const store = useStore();
store.state.isAbsolute = false; 
store.commit("isDashboard", false);
</script> 
<style scoped>
a {
  display: contents
}
</style>