
<template>
  <div
    v-show="layout === 'default'"
    :class="isDashboard.value ? 'min-height-400' : 'min-height-200'"
    class="position-absolute w-100 bg-success"
  />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar fixed-start ms-3 navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`    
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main"
  >
  <button type="button" class="cross-button border-0 bg-transparent" @click="closeMenu()" aria-label="Close" ><i class="custom-icon close-icon icon-lg"></i></button>
 
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <i
          class="custom-icon coffee-icon"
          alt="main_logo"
        ></i>

        <span class="ms-2 font-weight-bold me-2">Work Break</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />
  </aside>
</template>

<script setup>
import { computed ,ref } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
import { useHead } from '@vueuse/head';

useHead({
  title: 'Work Break - Dashboard',
  meta: [
    {
      name: 'description',
      content: 'Work Break Dashboard - Manage your work and break schedules.',
    },
    {
      property: 'og:title',
      content: 'Work Break - Dashboard',
    },
    {
      property: 'og:description',
      content: 'Manage your work and break schedules with Work Break platform.',
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ]});

let isDashboard = ref(false)
const store = useStore(); 
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
isDashboard.value = computed(() => store.state.isDashboard);
const closeMenu = () => { 
    const sidenav_show = document.querySelector("#app");
    store.commit("sidebarMinimize");
    sidenav_show.classList.add("g-sidenav-hidden");
    sidenav_show.classList.remove("g-sidenav-pinned");
    store.commit("isPinned", false);
    };
</script>

<style scoped>
.cross-button {
  display: none;
}
@media (max-width: 800px) {
  .cross-button {
    display: block !important;
    padding: 1em;
    right: 0;
    float: right;
    margin-top: .6em;
  }
}
</style>