<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold">Maximize Your Productivity – Start Managing Your Tasks Today!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Stay on top of your goals with efficient task management. <a @click="openSignIn" class="cursor-pointer text-success start-link">Sign in</a> to add tasks, set priorities, and track progress with detailed charts—boosting focus and productivity.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Task Management - WorkBreak",
  "Maximize your productivity with WorkBreak's task management tools. Sign in to manage tasks, set priorities, and track progress with detailed insights."
);
const openSignIn = () => {
try{
  const signInKey = document.querySelector('#signin_key');
      signInKey.click();
    }catch(error){
      console.error('Error break/get:', error);
      }
  }
</script>
 
<style scoped>
 
.start-link {
  text-decoration: underline;
  cursor: pointer;
  color: #86b7fe !important;
}
</style>