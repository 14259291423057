<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold">Stay Organized – Add Your First Task!</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">You haven’t added any tasks yet—organizing and prioritizing tasks is key to productivity. Start adding tasks with deadlines and priorities, and track progress using our clear charts to stay in control of your time.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Add Your First Task - WorkBreak",
  "Stay organized and boost productivity by adding your first task on WorkBreak. Set deadlines, prioritize tasks, and track progress with ease."
);
</script>
 
