<template>
<taskInfo1 v-if="!token"></taskInfo1>
<div v-if="inProgressTasks.length>0" class="card"> 
  <div class="p-3 pb-0 card-header">
    <div class="d-flex justify-content-between">
      <h6 class="mb-2"> Inprogress Tasks </h6> 
    </div>
  </div>
   
  <div class="row mt-3">
    <!-- Loop through tasks -->
    <div class="col-md-4 mb-4" v-for="(task, index) in inProgressTasks" :key="index">
      <div class="card h-100 d-flex flex-column">
        <div class="card-body flex-grow-1">
          <h5 class="card-title truncate-two-lines">{{ task.title }}</h5>
          <p class="card-text truncate-four-lines">{{ task.description }}</p>
          <div>
            <span class="badge bg-primary">{{ task.priority }}</span>
            <span class="ms-2 badge bg-warning">{{ task.status }}</span>
          </div>
        </div>
        <div class="text-end mt-auto pe-2 pb-2">
          <span class="me-2 mouse-pointer" @click="viewTask(task)">
            <i class="custom-icon view-icon"></i>
          </span>
          <span class="me-2 mouse-pointer" @click="editTask(task)">
            <i class="custom-icon edit-icon"></i>
          </span>
          <span class="mouse-pointer" @click="deleteTask(task._id)">
            <i class="custom-icon trash-icon"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

  </div>

   <!-- cuurent tasks --> 
   <loading v-if="isLoading"></loading>
    <taskInfo2 class="mt-3" v-if="token && !isLoading && (pastTasksData?.length===0 && currentTasksData?.length===0)"></taskInfo2>
<div v-if="token && !isLoading && currentTasksData?.length>0" class="card mt-4">
   <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12 mb-lg">            
          <div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="d-flex justify-content-between">
      <h6 class="mb-2">Tasks</h6>
      <p class="text-secondary text-sm font-weight-bolder">
        Scheduled tasks for next two weeks including today
      </p>
    </div>
  </div>
  <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Description             
               </th>
               <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Scheduled
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Priority
              </th>
               <th
                class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
           
            <tr v-for="(value, index) in currentTasksData" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto"> 
                    <h6 class="mb-0 font-weight-bold text-sm handle-large-text" :title="value.title">{{value.title}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.description">{{ value.description }}</p>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ convertDate(value.scheduled) }}</span>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.status }}</span>
              </td>  
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.priority }}</span>
              </td> 
              <td class="text-end">
                <span class="me-2 mouse-pointer" @click="viewTask(value)"><i class="custom-icon view-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="editTask(value)"><i class="custom-icon edit-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="deleteTask(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div></div>

          <div class="col-lg-3 col-sm-12 col-md-3"> 
            <div class="card">
              <canvas class="pb-2" id="currentDoughnutChart" width="300" height="300"></canvas>
            </div>

            <div class="card">
             <canvas class="pb-2" id="currentPieChart" width="300" height="300"></canvas>
             </div> 
          </div> 
        </div>
  </div>

  <div v-if="!token || (currentTasksData?.length===0 && pastTasksData?.length===0)" class="card z-index-2 p-3 mt-3">
    <taskInfo3 class="mt-3" v-if="!token"></taskInfo3>
    <loading v-if="isLoading"></loading>     
    <NoRecords class="mt-3" v-if="token && !isLoading && currentTasksData?.length===0"></NoRecords>      
    <carousel
      :images="[
        { src: '/carousel/ct2.png', alt: 'task' },
        { src: '/carousel/ct3.png', alt: 'task' },
        { src: '/carousel/st2.png', alt: 'task' },
        { src: '/carousel/st3.png', alt: 'task' },
      ]"
      id="carousel-cuurentTasks"
    /></div>
<!-- old tasks -->
 <!-- <taskInfo2 class="mt-3" v-if="token && pastTasksData?.length===0"></taskInfo2> -->
 <!-- <taskInfo3 class="mt-3" v-if="!token"></taskInfo3> -->
  <div v-if="token && pastTasksData?.length>0" class="card mt-5">
   <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12 mb-lg"> 
          <div class="card"> 
    <div class="p-3 pb-0 card-header">
    <div class="d-flex justify-content-between">
      <h6 class="mb-2">Tasks</h6> 
      <p class="text-secondary text-sm font-weight-bolder">
        Scheduled tasks for past Two weeks
   </p>
    </div>
  </div>
   
  <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Description             
               </th>
               <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Scheduled
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Status
              </th>
              <th
                class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Priority
              </th>
               <th
                class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
           
            <tr v-for="(value, index) in pastTasksData" :key="index">
              <td>
                <div class="d-flex px-2">
                  <div class="my-auto"> 
                    <h6 class=" text-sm font-weight-bold mb-0 handle-large-text" :title="value.title">{{value.title}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.description">{{ value.description }}</p>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ convertDate(value.scheduled) }}</span>
              </td>
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.status }}</span>
              </td>  
              <td class="text-center">
                <span class="text-sm align-middle font-weight-bold">{{ value.priority }}</span>
              </td> 
              <td class="text-end">
                 <span class="me-2 mouse-pointer" @click="viewTask(value)"><i class="custom-icon view-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="editTask(value)"><i class="custom-icon edit-icon"></i></span>
                <span class="ms-2 mouse-pointer" @click="deleteTask(value._id)"><i class="custom-icon trash-icon"></i></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div></div>

          <div class="col-lg-3 col-sm-12 col-md-3"> 
            <div class="card ">
              <canvas class="pb-2" id="pastDoughnutChart" width="300" height="300"></canvas>
            </div>

            <div class="card">
             <canvas class="pb-2" id="pastPieChart" width="300" height="300"></canvas>
             </div> 
          </div> 
        </div> 
        
  </div>
  <div v-if="token && !isLoading && isTotalTaskCounts>0" class="mt-5">
    <div class="col-12">
      <tasks @taskCounts="ontaskCounts"></tasks>
      </div> 
  </div> 
  <ViewTask v-if="showViewModal" :task-details="selectedTask" @close="showViewModal = false"></ViewTask>
  <AddTask v-if="showEdit" :edit-record="selectedTask"></AddTask>
  </template>

<script setup>

import {ref, onMounted, computed, nextTick} from 'vue'; 
import { convertDate } from '../../utilities/transforms';
import carousel from '../common/carousel.vue';
import taskInfo1 from '../common/task-info1.vue';
import taskInfo2 from '../common/task-info2.vue';
import taskInfo3 from '../common/task-info3.vue';
import ViewTask from './ViewTask.vue';
import loading from '../common/loading.vue';
import AddTask from './AddTask.vue';
import { drawCharts } from '../composables/TasksCharts';
import apiClient from '../../../api/apiClient';
import { deleteRecord } from '../composables/deleteRecords';
import tasks from '../../components/archives/Tasks.vue';
import Cookies from 'js-cookie';
import { useMeta } from '../../../views/components/composables/useMetaTags';
useMeta(
  'Task Details - WorkBreak', 
  'View task details, display tasks on work-break including priority, status, and description. Manage and track your tasks seamlessly with WorkBreak.'
);

let showEdit = ref(false);
let currentTasksData = ref([]);
let pastTasksData = ref([]);
let inProgressTasks = ref([]);
let showViewModal = ref(false);
let isTotalTaskCounts = ref(true);
let isLoading = ref(false);
const selectedTask = ref(null);
import { useStore } from "vuex";
import NoRecords from '../common/no-records.vue';
const store = useStore();
store.state.isAbsolute = false;
 const userId = Cookies.get('id');

 const ontaskCounts = () => {
  // if tasks count is 0 then clear all
  currentTasksData.value = [];
  pastTasksData.value = [];
  inProgressTasks.value = [];
  isTotalTaskCounts.value = false;
 }
const token = computed(() => {
      return Cookies.get('access_token');;
  });
  const viewTask = async (task) => { 
     showViewModal.value = false;
    await nextTick(); 
    selectedTask.value = task;
    showViewModal.value = true;
  };

  const editTask = async (task) => { 
     showEdit.value = false;
    await nextTick(); 
    selectedTask.value = {...task};
    showEdit.value = true;
  };

  const deleteTask = async (id) => { 
     const repsonseId = await deleteRecord(id, "task");
    console.log("delete response", repsonseId);
    if(repsonseId) { 
      location.reload(true);
    }
  };

  const loadInProgressRecords = async () => {
    inProgressTasks.value = [];
     try {
      const tasks = await apiClient.get('task/getInProgressTasks', {
        params: {
          userId: userId,
          isScheduled: true
        }
      });
      inProgressTasks.value = tasks.data;
    } catch (error) {
        console.error('Error tasks:', error);
      }
  }

  const loadData = async() => {
    pastTasksData.value = [];
    isLoading.value = true;
    try {
      const tasks = await apiClient.get('task/get', {
        params: {
          userId: userId,
          isScheduled: true
        }
      });

      const today = new Date();
      today.setUTCHours(0, 0, 0, 0); // Start of today;
      if(tasks?.data) {
      tasks.data.forEach(task => {
        const taskDate = new Date(task.scheduled);

        if (taskDate >= today) {
          currentTasksData.value.push(task);
        } else {
          pastTasksData.value.push(task);
          pastTasksData.value.sort((a, b) => new Date(b.scheduled) - new Date(a.scheduled));
        }
      });

      setTimeout(() => { 
         currentChart();
         pastChart();
        }, 200); 
    } else {
      console.error('No tasks');
    }  
       } catch (error) {
        console.error('Error tasks:', error);
      }
      finally {
        isLoading.value = false;
      }
  }

    const currentChart = async() => {  
       const statusData = currentTasksData.value.reduce((acc, item) => {
            acc[item.status] = (acc[item.status] || 0) + 1;
            return acc;
        }, {});
         if(statusData !== null && statusData !== undefined && Object.keys(statusData).length !== 0) {
          await drawCharts("currentDoughnutChart", statusData , 'Priority Distribution', 'Priority Distribution', 'pie');
         }
      const priorityData = currentTasksData.value.reduce((acc, item) => {
            acc[item.priority] = (acc[item.priority] || 0) + 1;
            return acc;
        }, {});
         if(priorityData !== null && priorityData !== undefined && Object.keys(priorityData).length !== 0) {
           await drawCharts("currentPieChart", priorityData , 'Priority Distribution', 'Priority Distribution', 'doughnut');
         }
        }

    const pastChart = async() => {  
       const statusData = pastTasksData.value.reduce((acc, item) => {
            acc[item.status] = (acc[item.status] || 0) + 1;
            return acc;
        }, {});
        if(statusData !== null && statusData !== undefined && Object.keys(statusData).length !== 0) {
          await drawCharts("pastDoughnutChart", statusData , 'Priority Distribution', 'Priority Distribution', 'pie');
        }
      const priorityData = pastTasksData.value.reduce((acc, item) => {
            acc[item.priority] = (acc[item.priority] || 0) + 1;
            return acc;
        }, {});
       if(priorityData !== null && priorityData !== undefined && Object.keys(priorityData).length !== 0) {
        await drawCharts("pastPieChart", priorityData , 'Priority Distribution', 'Priority Distribution', 'doughnut');
       }
    }
   

  onMounted( async() => {
    await loadInProgressRecords();
    await loadData();
  });

</script> 
 