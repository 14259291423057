<template>
  <div class="mb-3 card" :class="heading === 'Add Task'? 'bg-gray':''">
    <div class="p-3 card-body card-height">
      <div class="d-flex flex-row-reverse justify-content-between">
        <div class="text-center" id="addBreakManually"
         :class="isAdd ? 'icon bg-gradient-primary' : ''" @click="add()">
          <i v-if="isAdd" class="text-lg opacity-10 custom-icon add-icon mouse-pointer" aria-hidden="true"></i>
        </div>
        <div>
          <div class="row mb-1">
              <span :class="heading === 'current week' ? 'col-5' : 'col-12'" class="col-7 text-sm text-uppercase font-weight-bold">{{ heading }}</span>
              <span class="col-5 font-weight-bold text-end current-break" v-if="heading === 'current week'">{{ count }} Breaks</span>
            </div>
            <h5 class="mb-0 font-weight-bold">
              <span v-if="heading === 'Add break manually'">
                so far <span class="text-success">{{ count }}</span> break(s) for today 
              </span>
               <!-- <div class="row mb-1">
              <span :class="heading === 'current week' ? 'col-5' : 'col-12'" class="col-7 text-sm text-uppercase font-weight-bold">{{ heading }}</span>
              <span class="col-5 font-weight-bold text-end current-break" v-if="heading === 'current week'">{{ count }} Breaks</span>
            </div> -->
              <span v-if="heading === 'current week'">
                <span v-if="oneToTwoWeekPercentage>0" class="text-danger"><i class="custom-icon arrowUp-icon"></i>{{ Math.abs(oneToTwoWeekPercentage) }}%</span>
                <span v-if="oneToTwoWeekPercentage<0" class="text-success"><i class="custom-icon arrowDown-icon"></i>{{ Math.abs(oneToTwoWeekPercentage) }}%</span>
                <span v-if="oneToTwoWeekPercentage===0" class="text-secondary">No change</span> <span>compare to last week</span>
              </span>
              <span v-if="heading === 'Add Task'">
                <p class="text-sm mt-2 mb-0">                
                <a href="/tasks" class="cursor-pointer link-primary font-weight-bolder">Go to the task list to proceed with your tasks</a>
              </p>
              </span>
              <span class="text-sm font-weight-bolder text-success"></span></h5>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, computed } from 'vue';
import { useMeta } from '../../../src/views/components/composables/useMetaTags';

useMeta(
  'Breaks Overview',
  'View and manage your breaks for today and compare your current week’s performance with the previous week. You can also add breaks manually for better tracking.'
);

const emit = defineEmits(["update:emitUpdateBreaks"]);
// let counts = ref(null);

const props = defineProps({
  heading: {
    type: String,
    required: true
  }, 
  count: {type: Number, required: false, default: 0},
  percentage: {type: String},
  isAdd: {type: Boolean}
});
const oneToTwoWeekPercentage = computed(() => parseFloat(props.percentage));
const add = () => {
  emit('update:emitUpdateBreaks'); 
}

onMounted(()=>{
  console.log('update', props);
  // if(!props.count){
  //   counts.value = 0;
  // } else {
  //   counts.value = props.count;
  // }
})
</script>

<style scoped>
  .bg-gray {
    background: #e9ecef;
  }
  .current-break {
    margin-top: -4px;
  }
  .card-height {
    height: 82.39px;
  }
</style>

