
<template>
    <div ref="modalRef" class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
        <div class="modal-header color-black">
        <h5 class="modal-title">
            Break Details
        </h5>
        <h5 class="card-title">{{ convertDate(breakDetails.createdDate) }}</h5>
        </div>
        <div class="modal-body"> 
                <div class="row justify-content-center">
                <div class="col-12">                             
                        <h4 class="card-title">{{ breakDetails.type }}</h4>                        
                        <p class="card-text">{{ breakDetails.reason }}</p>
                        <div class="my-2">
                              <div class="text-secondary"> Started Time: <span class="font-weight-bold">{{ convertTo12HourFormat(breakDetails.startTime) }}</span></div>
                              <div class="text-secondary">Ending Time: <span class="font-weight-bold">{{ convertTo12HourFormat(breakDetails.endTime) }}</span></div>
                              <div class="text-secondary">Duration(s): <span class="font-weight-bold">{{ convertMinutesToHoursAndMinutes(breakDetails.duration) }}</span></div>
                        </div>                        
                    </div>
                    </div> 
            
        </div>
        <div class="modal-footer bg-gradient">
            <button type="button" class="btn btn-sm bg-gradient-success float-start" @click="closeDialog()">
                close
            </button>    
        </div>
        </div>
    </div>
    </div>
</template>
<script setup>
 import { ref, onMounted } from 'vue';
 import { convertDate, convertTo12HourFormat, convertMinutesToHoursAndMinutes } from '../../utilities/transforms';
import { Modal } from 'bootstrap';
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Break Details - WorkBreak",
  "View detailed information about your break including type, reason, duration, and start/end times. Manage your breaks effectively with insights into your workday."
);


const modalRef = ref(null);
let modal = null;

const { breakDetails } = defineProps({
  breakDetails: { type: Object, required: true }
});

const closeDialog = () => {
try{
  modal.hide();
  }catch(error){
      console.error('Error break/get:', error);
      }
};

const showDialog = () => {
try{
  if (modal) {
    modal.show();
  }
  }catch(error){
      console.error('Error break/get:', error);
      }
};

onMounted(() => {
try{
  modal = new Modal(modalRef.value, {
    backdrop: 'static',
    keyboard: false
  });
  showDialog();
  }catch(error){
      console.error('Error break/get:', error);
      }
});

</script>