<template>
<div class="card mb-3">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold">Task Management and Prioritization System</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">
Our platform offers a powerful task management system for adding, editing, updating, and deleting tasks. Users can assign priorities (Critical, Important, Normal) and statuses (Completed, In Progress, To Do), with detailed charts providing a clear overview of tasks by priority and status.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Task Management System - WorkBreak",
  "Efficiently manage and prioritize tasks with WorkBreak's task management system. Add, edit, and track tasks by priority and status with detailed charts for a clear overview."
);
</script>
 
