<template>
<div class="mt-5 p-3">
  <!-- Conditional Components -->
  
  <leavesInfo1 v-if="!token"></leavesInfo1>
  <leavesInfo2 v-if="token"></leavesInfo2>

  <!-- Form Section -->
  <div class="pt-2 px-2 px-md-10 mt-5">
    <h2>Log Your Leave(s)</h2>
    <form @submit.prevent="submitForm">
      <!-- Row 1: From Date and To Date -->
      <div class="row mb-3">
        <!-- From Date -->
        <div class="col-12 col-md-6">
          <label for="fromDate" class="form-label">From Date</label>
          <input
            type="date"
            id="fromDate"
            v-model="leave.fromDate"
            class="form-control"
            :class="{ 'is-invalid': validationErrors.fromDate }"
            @change="handleDateChange" 
          />
          <!-- Validation Message -->
          <div v-if="validationErrors.fromDate" class="invalid-feedback">
            Please select a valid start date.
          </div>
        </div>

        <!-- To Date (shown if multiple days of leave) -->
        <div class="col-12 col-md-6" v-if="isMultipleDays">
          <label for="toDate" class="form-label">To Date</label>
          <input
            type="date"
            id="toDate"
            v-model="leave.toDate"
            class="form-control"
            :class="{ 'is-invalid': validationErrors.toDate }"
            :min="nextDayAfterFromDate"
          />
          <!-- Validation Message -->
          <div v-if="validationErrors.toDate" class="invalid-feedback">
            Please select a valid end date.
          </div>
        </div>
      </div>

      <!-- Row 2: Reason -->
      <div class="row mb-3">
        <div class="col-12">
          <label for="reason" class="form-label">Reason</label>
          <textarea
            id="reason"
            v-model="leave.reason"
            class="form-control"
            :class="{ 'is-invalid': validationErrors.reason }"
            rows="4"
            placeholder="Enter reason for leave"
          ></textarea>
          <!-- Validation Message -->
          <div v-if="validationErrors.reason" class="invalid-feedback">
            Reason is required.
          </div>
        </div>
      </div>

      <!-- Checkbox for multiple days leave -->
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="isMultipleDays"
          id="multipleDaysCheck"
        />
        <label class="form-check-label" for="multipleDaysCheck">
          Apply for multiple days
        </label>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary w-100 mt-2">Add Leave(s)</button>
    </form>
  </div>

  <!-- Leave List Section -->
  <div class="pt-2 px-2 px-md-10 mt-5">
    <loading v-if="isLoading"></loading>
    <div class="table-responsive pt-2" v-if="!isLoading && leavesList?.length > 0">
      <table class="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Reason
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Day(s)
            </th>
            <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              From Date
            </th>
            <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              To Date
            </th>
            <th class="text-uppercase text-end text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, index) in leavesList"
            :key="index"
            :class="{ 'disabled-color': isEdit && editedIndex === index }"
          >
            <td class="text-truncate">
              <div class="d-flex px-2">
                <div class="my-auto">
                  <h6 class="mb-0 text-sm handle-large-text" :title="value.reason">
                    {{ value.reason }}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm font-weight-bold mb-0 handle-large-text" :title="value.days">
                {{ value.days }}
              </p>
            </td>
            <td class="text-center">
              <span class="text-sm align-middle font-weight-bold">
                {{ convertDate(value.fromDate) }}
              </span>
            </td>
            <td class="text-center">
              <span class="text-sm align-middle font-weight-bold">
                {{ value.toDate ? convertDate(value.toDate) : value.toDate }}
              </span>
            </td>
            <td class="text-end">
              <span class="ms-2 mouse-pointer" @click="editLeaves(value, index)">
                <i class="custom-icon edit-icon"></i>
              </span>
              <span class="ms-2 mouse-pointer" @click="deleteLeaves(value._id)">
                <i class="custom-icon trash-icon"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Carousel Section when there are no leaves -->
    <div v-if="!isLoading && leavesList?.length === 0" class="card z-index-2 p-3">
      <noRecords></noRecords>
      <carousel
        :images="[ { src: '/carousel/l1.png', alt: 'break view' }, { src: '/carousel/l2.png', alt: 'break view' }, { src: '/carousel/l3.png', alt: 'break view' } ]"
        id="carousel-bv"
      />
    </div>
  </div>
</div>

</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { isAuth } from '../composables/userAuth';
import leavesInfo1 from '../common/leaves-info1.vue';
import leavesInfo2 from '../common/leaves-info2.vue';
import noRecords from '../common/no-records.vue';
import loading from '../common/loading.vue';
import carousel from '../common/carousel.vue';
import apiClient from '../../../api/apiClient';
import { deleteRecord } from '../composables/deleteRecords';
import { getDaysBetweenDates, convertDate, formatDateToDatetimeLocal } from "../../utilities/transforms";
import Cookies from 'js-cookie'; 
import { useStore } from "vuex";
import { useMeta } from '../composables/useMetaTags';
useMeta(
  "Work Break Leave - WorkBreak",
  "Manage your leaves effortlessly with WorkBreak. Add or edit leave details, track leave days, and stay on top of your schedules."
);

const store = useStore();
store.state.isAbsolute = false; 
store.commit("isDashboard", false);
const userId = Cookies.get('id');
const leave = ref({
  fromDate: '',
  days: 1,
  toDate: '',
  reason: '',
  userId: userId
});

const leavesList = ref([]);
const isLoading = ref(false);

const token = computed(() => Cookies.get('access_token'));
const isMultipleDays = ref(false); 
 const editedIndex = ref(null);
const isEdit = ref(false); 
const validationErrors = ref({
  fromDate: false,
  toDate: false,
  reason: false,
});

const nextDayAfterFromDate = computed(() => {
      if (leave.value.fromDate) {
        const date = new Date(leave.value.fromDate);
        date.setDate(date.getDate() + 1); // Add one day
        return date.toISOString().split('T')[0]; // Return the date in YYYY-MM-DD format
      }
      return '';
    });

const deleteLeaves = async (id) => { 
     const repsonseId = await deleteRecord(id, "leaves");
    console.log("delete response", repsonseId);
    if(repsonseId) { 
      location.reload(true);
    }
  };

  const editLeaves = async (values, index) => { 
    if (values.fromDate) {
    values.fromDate = formatDateToDatetimeLocal(values.fromDate);
  } 
  if (values.toDate) {
    isMultipleDays.value = true;
    values.toDate = formatDateToDatetimeLocal(values.toDate);
  } else {
    isMultipleDays.value = false;
  } 
  isEdit.value = true;
  editedIndex.value = index; 
  leave.value = {...values};

  };
// Submit form handler
const submitForm = async() => {
  if (isAuth()) {
    console.log("Please enter", isAuth());
    resetValidation();  

    validationErrors.value.fromDate = leave.value.fromDate === '';
    validationErrors.value.reason = leave.value.reason.trim() === '';

    if (isMultipleDays.value) {
      validationErrors.value.toDate = leave.value.toDate === '' || leave.value.toDate < leave.value.fromDate;
    }

    // If no validation errors, proceed with form submission
    if (!validationErrors.value.fromDate && !validationErrors.value.reason && (!isMultipleDays.value || !validationErrors.value.toDate)) {
    try {
      const editId = leave.value._id;
      delete leave.value._id;
            if (!isMultipleDays.value) {
        leave.value.toDate = '';
      } 
      if(leave.value.toDate && leave.value.toDate !== '' && leave.value.fromDate !== leave.value.toDate){
        const numberOfDays = getDaysBetweenDates(leave.value.fromDate, leave.value.toDate);
        leave.value.days = numberOfDays;
      } else {
        leave.value.days = 1;
      }

      if (editId) {
        await apiClient.put(`/leaves/edit/${editId}`, leave.value);
      } else {
        await apiClient.post('/leaves/add', leave.value);
      }
      await getLeaves();
      leave.value.fromDate = "";
      leave.value.toDate = ""; 
      leave.value.days=1;
      leave.value.reason = "";
      isMultipleDays.value = false;
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      isEdit.value = false;
    }
    }
  }
};

const getLeaves = async() => {
  try {
    isLoading.value = true;
      if(userId) {  
     const leaves = await apiClient.get('leaves/get', {
        params: {
          userId: userId
        }
      });
      if(leaves?.data){
        leavesList.value = leaves.data;
      }
    }
    } catch (error) {
      console.error('Error:', error); 
    }
    finally {
      isLoading.value = false;
    }
  }

 onMounted( async() => {
   await getLeaves();
 }); 

// Reset validation errors
const resetValidation = () => {
  validationErrors.value = {
    fromDate: false,
    toDate: false,
    reason: false,
  };
};

// Handle the change in date selection
const handleDateChange = () => {
  if (!isMultipleDays.value) {
    leave.value.toDate = ''; // Clear the toDate if single day leave is selected
  }
};
</script>

<style scoped>
.container {
  max-width: 600px; 
}
.disabled-color {
  background-color: #f0f0f0;  
  pointer-events: none;  
  opacity: 0.6; 
}
</style>
