<template>
  <div class="m-2 p-2"> 
    <h3>Delete Your Account and Data</h3>
    <p><strong>Effective Date:</strong> October 29, 2024</p>
    <p class="card p-3">At WorkBreak, we respect your right to control your personal data. If you wish to delete your account and all associated data, please read the following instructions carefully.</p>
    <h4>Steps to Delete Your Account</h4>
    <ol>
        <li><strong>Log In</strong>: Please log into your account to verify your identity.</li>
        <li><strong>Account Deletion Request</strong>:
            <ul>
                <li>Go to your profile settings, and navigate to <strong>Delete Account</strong>.</li>
                <li>Follow the prompts to confirm the deletion.</li>
            </ul>
        </li>
        <li><strong>Data Removal</strong>: Once your account is deleted, the following information will be removed:
            <ul>
                <li>Your name and email.</li>
                <li>Stored breaks, tasks, and leave data.</li>
                <li>All user preferences and settings.</li>
            </ul>
        </li>
    </ol>
    <blockquote>
        <p><strong>Note</strong>: Once deleted, your account and data cannot be recovered.</p>
    </blockquote>
    <h4>Questions or Concerns?</h4>
    <p>If you encounter any issues or have questions about the deletion process, feel free to contact us at:</p>
    <ul>
        <li><strong>Email</strong>: info@work-break.com</li>
    </ul>
     </div>
</template>
<script setup>
import { useStore } from "vuex";
import { useMeta } from '../composables/useMetaTags';
useMeta(
  "Delete Account - WorkBreak",
  "Take control of your data with WorkBreak. Learn how to delete your account and associated information securely and effortlessly."
);

const store = useStore();
store.state.isAbsolute = false; 
store.commit("isDashboard", false);
</script> 
<style scoped>
a {
  display: contents
}
</style>