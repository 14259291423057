<template>
<div class="card">
  <div class="p-3 pb-0 card-header">
    <div class="row">
      <div class="col-md-8 d-flex align-items-center">
        <h6 class="mb-0 text-lg fw-bold">Break Management and Analytics Platform</h6>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <p class="text-md">Our platform helps users log, edit, and track various breaks like lunch, coffee, or school pickups with time, date, and reason details. It provides charts and analytics for insights into break patterns, enabling better time management and productivity.</p>
  </div>
</div>
</template>
<script setup>
import { useMeta } from '../composables/useMetaTags';

useMeta(
  "Break Management - WorkBreak",
  "Log, track, and analyze your breaks effectively with detailed time, date, and reason entries. Gain insights through charts and improve your work-life balance."
);

</script>
 
